<template>
  <div class="productManager">
    <el-card class="box-card" shadow="never">
      <el-form slot="header" class="searchFrom" size="small" :inline="true" lable-width="80px"
               :model="searchForm">
        <el-form-item label="宝贝名称:">
          <el-input v-model="searchForm.goodsName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="宝贝ID:">
          <el-input v-model="searchForm.goodsId" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="价格:">
          <el-col :span="11">
            <el-input v-model="searchForm.priceMin" placeholder="" @blur="blur1"></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="searchForm.priceMax" placeholder="" @blur="blur2"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="库存量:" v-if="false">
          <el-col :span="11">
            <el-input v-model="searchForm.stockMin" placeholder=""></el-input>
          </el-col>
          <el-col class="sctp-tc" :span="2">~</el-col>
          <el-col :span="11">
            <el-input v-model="searchForm.stockMax" placeholder=""></el-input>
          </el-col>
        </el-form-item>
        <el-button size="small" class="sctp-square" @click="resetSearchForm">重置</el-button>
        <el-button size="small" type="primary" class="sctp-square" @click="searchProduct">搜索
        </el-button>
        <el-button @click="goPage('/shop/release/sourcecode')" class="sctp-square" type="primary"
                   size="small" icon="el-icon-plus">发布源码
        </el-button>
      </el-form>
      <el-table
        @selection-change="selectionChange"
        :data="tableData"
      >
        <el-table-column
          label="商品信息">
          <template slot-scope="item">
            <div class="sctp-flex sctp-flex-column">
              <div>#{{ item.row.productId }}</div>
              <div class="sctp-flex">
                <div class="sctp-mar-r10">
                  <el-image
                    style="width: 65px; height: 65px"
                    :src="item.row.productLogo"
                    fit="contain">
                    <div slot="error" class="sctp-flex sctp-flex-center sctp-bg-gray"
                         style="height: 100%;">
                      暂无图片
                    </div>
                  </el-image>
                </div>
                <div class="sctp-flex-item-1">
                  <div class="sctp-bold sctp-ellipsis--l2">{{ item.row.productName }}</div>
                  <div class="sctp-font-12 sctp-ellipsis--l2">{{ item.row.remark }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="售价（元）">
          <template slot-scope="scope">
            <div class="sctp-flex sctp-flex-center sctp-flex-column">
              <div style="font-weight: bold;" class="sctp-font-16">{{
                  (scope.row.productPrice || 0).toMoney()
                }}
              </div>
              <template v-if="scope.row.underlinedPrice">
                <div style="text-decoration: line-through;" class="sctp-info">
                  {{ scope.row.underlinedPrice.toMoney() }}
                </div>
              </template>
              <i
                @click="optionsConfig.onClick('editPrice', scope.row, scope.$index)"
                class="el-icon-edit"></i>
            </div>
            <div class="sctp-tc" style="cursor:pointer;"
                 @click.prevent="dealModelConfig.showDialog(scope.row)">
              <el-tag size="mini">
                {{
                  [' ', '私下交易', '担保交易', '私下/担保'][scope.row.dealModel] || ' '
                }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="stock"
          width="100px"
          align="center"
          label="库存/已售">
          <template slot-scope="scope">
            <div>{{ scope.row.stock }}
              <i
                @click="optionsConfig.onClick('editStock', scope.row, scope.$index)"
                class="el-icon-edit"></i>/ {{ scope.row.salesVolume }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="90px"
          label="是否上架">
          <template slot-scope="scope">
            <template v-if="scope.row.auditFlag !== 3">
              <el-tooltip
                v-if="scope.row.status === 1"
                class="item" effect="dark"
                :content="`上架截至日期${dateToString(scope.row.shelvesDeadline)}`"
                placement="top">
                <el-tag size="small" effect="dark">
                  已上架
                </el-tag>
              </el-tooltip>
              <el-tag size="small" type="info" effect="dark" v-if="scope.row.status === 0">
                未上架
              </el-tag>
            </template>
            <template v-else>
              <el-tooltip class="item" effect="dark" content="强制下架的商品无法上架" placement="top-start">
                <el-tag size="small" type="warning" effect="dark">
                  强制下架
                </el-tag>
              </el-tooltip>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="审核结果">
          <template slot-scope="scope">
            <el-tag size="small" type="danger" effect="dark" v-if="scope.row.auditFlag === 0">
              未审核
            </el-tag>
            <el-tag size="small" effect="dark" v-else-if="scope.row.auditFlag === 1">
              已通过
            </el-tag>
            <template v-else-if="scope.row.auditFlag === 2 || scope.row.auditFlag === 3">
              <el-tooltip effect="dark" :content="scope.row.auditMemo" placement="top">
                <el-tag size="small" type="danger" effect="dark">
                  {{ scope.row.auditFlag === 2 ? '未通过' : '强制下架' }}
                </el-tag>
              </el-tooltip>
            </template>
            <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
              <a
                @click="optionsConfig.onClick('history', scope.row, scope.$index)"
              ><i class="el-icon-question"></i></a>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          width="100px"
          label="最近更新">
          <template slot-scope="scope">
            {{ dateToString(scope.row.updateTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <div class="sctp-tc">
              <template v-if="scope.row.temporaryStorage">
                <div class="sctp-mar-b5">
                  <el-button
                    @click="goPage(`/shop/release/sourcecode/edit?goodsId=${scope.row.productId}`)"
                    size="mini"
                  >
                    继续完善
                  </el-button>
                </div>
              </template>
              <template v-else>
                <div class="sctp-mar-b5" v-if="scope.row.status === 0">
                  <el-button
                    size="mini"
                    @click="optionsConfig.onClick('up', scope.row, scope.$index)"
                  >
                    上架
                  </el-button>
                </div>
                <div class="sctp-mar-b5" v-else>
                  <el-button
                    size="mini" framework
                    @click="optionsConfig.onClick('down', scope.row, scope.$index)"
                  >
                    下架
                  </el-button>
                </div>
                <div class="sctp-mar-b5">
                  <el-button
                    @click="optionsConfig.onClick('edit', scope.row, scope.$index)"
                    size="mini"
                  >
                    编辑
                  </el-button>
                </div>
                <div class="sctp-mar-b5">
                  <el-button
                    size="mini" type="primary"
                    @click="optionsConfig.onClick('preview', scope.row, scope.$index)"
                  >
                    预览
                  </el-button>
                </div>
                <div class="sctp-mar-b5">
                  <el-button
                    size="mini" type="success"
                    @click="optionsConfig.onClick('evaluate', scope.row, scope.$index)"
                  >
                    评价
                  </el-button>
                </div>

              </template>
              <div class="sctp-mar-b5">
                <el-button
                  size="mini" type="danger"
                  :disabled="scope.row.salesVolume > 0"
                  :title="scope.row.salesVolume ? '已售源码不能删除' : ''"
                  @click="optionsConfig.onClick('del', scope.row, scope.$index)"
                >
                  删除
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="pageConfig.page"
          :page-size="pageConfig.limit"
          @size-change="pageConfig.handleSizeChange"
          @current-change="pageConfig.handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="pageConfig.total">
        </el-pagination>
      </div>
      <el-dialog title="审核历史" class="auditHistory" :visible.sync="showAuthHistory">
        <audit-history type="SOURCECODE" :id="activeProductId"></audit-history>
      </el-dialog>

      <el-dialog width="600px" center title="本源码上架需支付服务费" :visible.sync="payDialogConfig.showFlag">
        <pay-dialog type="SOURCECODE" @pay="payDialogConfig.onPay"></pay-dialog>
      </el-dialog>
    </el-card>
    <el-dialog class="view_comment_dialog" width="800px" center title="商品评价"
               :visible.sync="viewCommentConfig.showFlag">
      <comment type="SOURCECODE" :id="activeProductId"></comment>
    </el-dialog>
    <el-dialog width="400px" center title="选择交易方式" :visible.sync="dealModelConfig.show">
      <div style="height: 280px;">
        <el-radio-group v-model="dealModelConfig.value" @change="dealModelConfig.onDealModelChange">
          <el-radio class="mg-b20" :label="1">双方私下交易</el-radio>
          <el-radio class="mg-b20" :label="2">平台担保交易</el-radio>
          <el-radio class="mg-b20" :label="3">双方私下交易/平台担保交易</el-radio>
        </el-radio-group>
      </div>
    </el-dialog>
    <el-dialog width="400px" center title="编辑价格" :visible.sync="updatePriceConfig.show">
      <update-price @success="updatePriceConfig.show = false;searchProduct()" type="SOURCECODE"
                    :model="updatePriceConfig.model" :id="activeProductId"></update-price>
    </el-dialog>
  </div>
</template>

<script>
const config = require('../../../../resources/js/config');
import {goods} from "../../../../apis";

export default {
  name: 'shopProductManager',
  components: {
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay'),
    comment: () => import('@PAGE/shop-manage/production-manage/production-commnet/CommentContent'),
    UpdatePrice: () => import('@CMP/product/UpdatePrice'),
  },
  data() {
    return {
      showAuthHistory: false,
      searchForm: {
        goodsName: null,
        goodsId: null,
        priceMin: null,
        priceMax: null,
        stockMin: null,
        stockMax: null
      },
      tableData: [],
      selectedItems: [], // 选中项
      updatePriceConfig: {
        show: false,
        model: {},
      },
      optionsConfig: {
        options: {
          history: ({item, index}) => {
            this.showAuthHistory = true;
          },
          del: ({item, index}) => {
            this.$confirm(`确认删除？`, '提示', {
              type: 'warning'
            }).then(() => {
              goods.del({
                id: item.productId
              }).then(res => {
                this.$message.success('删除成功');
                this.searchProduct();
              });
            });
          },
          up: ({item, index}) => {
            if (item.auditFlag === 0 || item.auditFlag === 2) {
              this.$confirm(`您的源码暂未通过审核，无法上架`, '提示', {
                type: 'warning'
              });
              return;
            }
            if (item.auditFlag === 3) {
              this.$confirm(`您的源码由于‘${item.auditMemo}’被强制下架,请重新编辑`, '提示', {
                type: 'warning'
              }).then(() => {
                this.$router.push({
                  path: '/shop/release/sourcecode/edit',
                  query: {
                    goodsId: item.productId
                  }
                });
              });
              return;
            }
            this.doGoodsUpperAndLowerShelves(item.productId, 1).then(res => {
              this.$message.success('上架成功')
            });
          },
          down: ({item, index}) => {
            this.doGoodsUpperAndLowerShelves(item.productId, 0).then(res => {
              this.$message.success('下架成功')
            });
          },
          preview: ({item, index}) => {
            this.openPage(`/sourcecode/view/${item.productId}`);
          },
          editPrice: ({item, index}) => {
            this.updatePriceConfig.show = true;
            this.updatePriceConfig.model = {
              salePrice: item.productPrice,
              realPrice: item.underlinedPrice,
            }
          },
          editStock: ({item, index}) => {
            this.$prompt('请输入库存', '编辑库存', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /^(?!0)(?:[0-9]{1,4}|10000)$/,
              inputErrorMessage: '库存限制范围 1 - 10000'
            }).then(({value}) => {
              goods.updateStock({
                id: item.productId,
                stock: value,
                type: 'SOURCECODE',
              }).then(res => {
                item.stock = value;
                this.$message.success('修改成功');
              })
            })
          },
          edit: ({item, index}) => {
            let editPage = () => {
              this.$router.push({
                path: '/shop/release/sourcecode/edit',
                query: {
                  goodsId: item.productId
                }
              })
            };
            if (item.salesVolume) {
              this.$confirm('已出售的商品无法编辑，请重新添加！', '提示', {
                type: 'warning',
                showCancelButton: false,
              })
              return;
            }
            if (item.auditFlag === 1) {
              this.$confirm(`${item.status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
                type: 'warning'
              }).then(() => {
                if (item.status === 1) {
                  this.doGoodsUpperAndLowerShelves(item.productId, 0)
                  .then(() => {
                    editPage();
                  });
                } else {
                  editPage();
                }
              });
              return;
            }
            editPage();
          },
          evaluate: ({item, index}) => {
            // this.viewCommentConfig.show(item.productId);
            this.openPage(`/evaluation/SOURCECODE/${item.productId}`);
          }
        },
        onClick: (option, item, index) => {
          this.activeProductId = item.productId;
          if (this.optionsConfig.options[option]) {
            this.optionsConfig.options[option].call(null, {item: item, index: index});
          }
        }
      },
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.searchProduct()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.searchProduct()
        }
      },
      /**
       * 付费弹框
       */
      payDialogConfig: {
        showFlag: false,
        showUpperShelvesDialog: () => {
          this.payDialogConfig.showFlag = true
        },
        onPay: ({payWay, price, date}) => {
          return this.$request.post({
            reqcode: '1120',
            reqdesc: '商品上架',
            date: date,
            payWay: payWay,
            productId: this.activeProductId,
            userId: this.user.userId
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.searchProduct()
          })
        }
      },
      viewCommentConfig: {
        showFlag: false,
        show: (id) => {
          this.viewCommentConfig.showFlag = true;
          this.activeProductId = id
        }
      },
      activeProductId: null,
      dealModelConfig: {
        value: null,
        show: false,
        showDialog: (goods) => {
          this.dealModelConfig.show = true;
          this.dealModelConfig.value = goods.dealModel;
          this.activeProductId = goods.productId;
        },
        onDealModelChange: (label) => {
          goods.changeDealModel({
            productId: this.activeProductId,
            value: label,
            type: 1,
          }).then(res => {
            this.$message.success('修改成功');
            this.searchProduct();
          })
        }
      },
    }
  },
  beforeMount() {
    this.searchProduct()
  },
  computed: {},
  methods: {
    blur1() {
      if (this.searchForm.priceMax != null && this.searchForm.priceMin != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.searchForm.priceMin))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.searchForm.priceMin=0
          return
        }
        let a = this.searchForm.priceMax * 1;
        let b = this.searchForm.priceMin * 1;
        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.searchForm.priceMin=0
          return
        }
        if (a < b) {
          this.$alert("最低价格不能超过最高价格")
          this.searchForm.priceMin = null
        }
      }
    },
    blur2() {
      if (this.searchForm.priceMax != null && this.searchForm.priceMin != null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.searchForm.priceMax))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.searchForm.priceMax=100000000
          return
        }
        let a = this.searchForm.priceMax * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.searchForm.priceMax=1000000000
          return
        }
        let b = this.searchForm.priceMin * 1;
        if (a < b) {
          this.$alert("最高价格不能低于最低价格")
          this.searchForm.priceMax = 100000000
        }
      }
    },
    resetSearchForm() {
      Object.keys(this.searchForm).forEach(key => {
        this.searchForm[key] = null;
      })
    },
    searchProduct() {
      const {page, limit} = this.pageConfig;
      const params = {
        reqcode: '1013',
        reqdesc: '商品查询',
        page: page,
        limit: limit,
        userId: this.user.userId,
        ...this.searchForm
      };
      this.$request.post(params).then(res => {
        const {retdata = [], count = 0} = res;
        this.tableData = retdata;
        this.pageConfig.total = count
      })
    },
    doGoodsUpperAndLowerShelves(goodsId, status) {
      return this.$request.post({
        reqcode: '1039',
        reqdesc: '商品上下架',
        goodsId: goodsId,
        status: status,
        userId: this.user.userId
      }).then(res => {
        this.searchProduct()
      }).catch(res => {
        if (res && res.retCode === '9002') {
          this.payDialogConfig.showUpperShelvesDialog();
        }
        return Promise.reject(res);
      })
    },
    selectionChange(selectedArray) {
      this.selectedItems = selectedArray
    },
  }
}
</script>

<style scoped lang="scss">
.productManager {
  ::v-deep {
    td {
      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      :hover .el-icon-edit {
        display: inline-block;
      }
    }
  }
}

.searchFrom {
  ::v-deep {
    .el-form-item__content {
      width: 130px !important;
    }

    .el-input__inner {
      border-radius: 0;
    }
  }
}

.auditHistory ::v-deep {
  & .el-dialog__body {
    padding-top: 0;
  }

  & .el-dialog {
    width: 80%;
  }

  & .el-dialog__body {
    max-height: 50vh;
    overflow-y: auto;
  }
}

.view_comment_dialog ::v-deep .el-dialog__body {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
